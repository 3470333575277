<template>
  <div class="news-details">
    <div class="news-left">
      <div class="left-title">
        {{ ctx.title }}
      </div>
      <div class="time">
        <span>发布时间： {{ ctx.time }}</span>
        <span>来源：{{ ctx.target }}</span>
      </div>
      <div class="ctx-content" v-html="ctx.details"></div>
      <div class="prev">
        上一篇：<span @click="prev"> {{ up.title }}</span>
      </div>
      <div class="next">
        下一篇：<span @click="next"> {{ down.title }}</span>
      </div>
    </div>
    <div class="news-right">
      <div class="right-title">
        最新新闻
      </div>
      <div class="right-content">
        <div
          class="right-item"
          v-for="item in contents"
          :key="item.id"
          @click="routeTo(item)"
        >
          <div class="item-type">
            {{ item.newtype }}
          </div>
          <div class="item-content">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGetNewsDetails } from "../../api/index";
export default {
  data() {
    return {
      contents: [],
      ctx: {
        title: "",
        time: "",
        target: "",
        details: "",
      },
      up: {},
      down: {},
    };
  },
  methods: {
    prev() {
      if (this.up.id != "-1") {
        this.$router.push({
          name: "NewsDetails",
          params: {
            id: this.up.id,
          },
        });
      }
    },
    next() {
      if (this.down.id != "-1") {
        this.$router.push({
          name: "NewsDetails",
          params: {
            id: this.down.id,
          },
        });
      }
    },
    init() {
      let id = this.$route.params.id;
      apiGetNewsDetails(id).then((data) => {
        if (data.data.status == 1) {
          this.contents = data.data.news;
          this.ctx = {
            title: data.data.detail.title,
            time: this.forMatter(data.data.detail.create_time * 1),
            target: data.data.detail.laiyuan,
            details: data.data.detail.content,
          };
          if (!!data.data.up.id) {
            this.up = data.data.up;
          } else {
            this.up = {
              id: "-1",
              title: "无",
            };
          }

          if (!!data.data.down.id) {
            this.down = data.data.down;
          } else {
            this.down = {
              id: "-1",
              title: "无",
            };
          }
        }
      });
    },
    forMatter(d) {
      var date = new Date(d);
      var getyear = date.getFullYear();
      var getmonth =
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      var getday = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();

      return getyear + "." + getmonth + "." + getday;
    },
    routeTo(item) {
      if (item.id != this.$route.params.id) {
        this.$router.push({
          name: "NewsDetails",
          params: {
            id: item.id,
          },
        });
      }
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {

        this.init();
      },
    },
  },
};
</script>
<style lang="less" scoped>
.news-details {
  width: 100%;
  padding: 0 160px 20px;
  height: 100%;
  display: flex;
  .news-right {
    width: 518px;
    padding: 87px 10px;
    .right-title {
      color: #000;
      font-size: 35px;
      // font-weight: 600;
      font-family: "苹方-简 中黑体";
      src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
    }
    .right-content {
      margin-top: 40px;
      .right-item {
        background-color: #f8f8f8;
        margin-bottom: 20px;
        padding: 22px 29px;
        cursor: pointer;
        width: 518px;
        height: 160px;
        .item-type {
          color: #666;
          font-size: 22px;
          margin-bottom: 5px;
          font-family: "苹方-简 常规体";
          src: url("../../assets/fonts/PINGFANG MEDIUM.TTF");
        }
        .item-content {
          color: #000;
          font-size: 26px;
          line-height: 35px;
          // font-weight: 600;
          font-family: "苹方-简 中黑体";
          src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
        }
      }
    }
  }
  .news-left {
    flex: 1;
    padding: 87px 170px 20px 10px;
    font-size: 24px;
    font-family: '苹方-简 常规体';
    .left-title {
      color: #000;
      font-size: 35px;
      // font-weight: 600;
      font-family: "苹方-简 中黑体";
      src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
     
    }
    .time {
      font-size: 22px;
      color: #ccc;
      margin: 15px 0 40px;
      span {
        margin-right: 121px;
      }
    }
    .ctx-content {
      color: #666;
      line-height: 38px;
      font-size: 22px;
    }
     .ctx-content p img{
       width: 100% !important;
       height: 100% !important;
     }
    .prev {
      margin-top: 60px;
      border-top: 1px solid #ccc;
      padding: 27px 4px;
      color: #666;
      font-size: 24px;
      cursor: pointer;
      font-family: "苹方-简 常规体";
      src: url("../../assets/fonts/PINGFANG MEDIUM.TTF");
      &:hover {
        color: #e95506;
      }
    }
    .next {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 27px 4px;
      color: #666;
      font-size: 24px;
      cursor: pointer;
      font-family: "苹方-简 常规体";
      src: url("../../assets/fonts/PINGFANG MEDIUM.TTF");
      &:hover {
        color: #e95506;
      }
    }
  }
}
</style>
